<!-- 报价中心 -->
<template>
  <div class="quotes-container">
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="报价中心" name="first">
        <div class="welcome">
          欢迎来到<br />
          三圆客服报价中心 <br />
          <el-button
            type="primary"
            @click="
              () => {
                this.$router.push({ path: '/quoted/newCus' });
              }
            "
            >开始报价</el-button
          >
          <div style="color: #666">注: 报价中心可为客户估算订单金额</div>
        </div>
        <div class="footer">
          <el-button
            @click="
              () => {
                this.$router.push('/quoted/quoted-setting');
              }
            "
            type="text"
            >报价设置</el-button
          >
        </div>
      </el-tab-pane>
      <el-tab-pane label="报价列表" name="second"  v-loading="loading" element-loading-text="数据较多，拼命加载中...">
        <!-- 筛选条件 -->
        <div class="common-screen-container">
          <div class="common-input-container">
            <span>客户名称:</span>
            <el-input
              v-model="params.customerName"
              placeholder="请输入内容"
              class="common-screen-input"
              clearable
            ></el-input>
          </div>
          <div class="common-input-container">
            <span>店铺名称:</span>
            <el-input
              v-model="params.shopName"
              placeholder="请输入内容"
              class="common-screen-input"
              clearable
            ></el-input>
          </div>
          <div class="common-input-container">
            <span>所属平台/所属类目:</span>
            <a-cascader
              class="common-screen-input"
              v-model="platformId"
              :options="platList"
              :fieldNames="{
                label: 'platformName',
                value: 'id',
                children: 'children'
              }"
              placeholder="请选择"
              change-on-select
              @change="commonFun"
            />
          </div>
          <br />
          <div class="common-input-container">
            <span>客服类型:</span>
            <el-select
              class="common-screen-input"
              v-model="params.serviceType"
              placeholder="请选择"
              @change="commonFun"
              clearable
              filterable
            >
              <el-option
                v-for="item in serviceList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="common-input-container">
            <span>约定数据标准:</span>
            <el-select
              class="common-screen-input"
              v-model="params.dataType"
              placeholder="请选择"
              @change="commonFun"
              clearable
              filterable
            >
              <el-option
                v-for="item in dataTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="common-input-container">
            <span>创建人:</span>
            <el-input
              v-model="params.createId"
              placeholder="请输入内容"
              class="common-screen-input"
              clearable
            ></el-input>
          </div>
          <br />
          <div class="common-input-container">
            <common-date
              @commonGetDate="commonGetDate"
              ref="commonReset"
            ></common-date>
          </div>
          <el-button
            class="common-screen-btn"
            type="primary"
            @click="
              () => {
                getQuotesList();
              }
            "
            >查 询</el-button
          >
          <el-button class="common-screen-btn" plain @click="() => resetBtn()"
            >重 置</el-button
          >
        </div>
        <!-- 表格 -->
        <el-table
          :data="tableData"
          :span-method="objectSpanMethod"
          class="common-table"
        >
          <el-table-column
            prop="qSn"
            label="报价编号"
            width="120"
            :formatter="tableColumn"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="() => seeMore(scope.row)"
                >{{ scope.row.qSn }}</el-button
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="name"
            label="客户名称"
            :formatter="tableColumn"
          >
          </el-table-column>
          <el-table-column
            prop="shopName"
            label="店铺名称"
            width="120"
            :formatter="tableColumn"
          >
          </el-table-column>
          <el-table-column
            prop="sumPrice"
            label="报价金额"
            :formatter="tableColumn"
          >
            <template slot-scope="scope">
              <span class="sum-price">{{ scope.row.sumPrice.toFixed(2) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="platFormName" label="所属平台" :formatter="tableColumn">
          </el-table-column>
          <el-table-column prop="categoryName" label="所属类目" :formatter="tableColumn">
          </el-table-column>
          <el-table-column
            prop="subaccountNum"
            label="协议子账号数量"
            width="120"
            :formatter="tableColumn"
          >
          </el-table-column>
          <el-table-column prop="serviceNum" label="客服人数" width="100" :formatter="tableColumn">
          </el-table-column>
          <el-table-column label="协议客服工作时间" width="150" :formatter="tableColumn"> 
            <template slot-scope="scope">
              <span>{{ scope.row.startHour }}</span
              ><br />
              <span>{{ scope.row.endHour }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="workHour" label="客服工作时长" width="120" :formatter="tableColumn">
          </el-table-column>
          <el-table-column
            prop="shopConsultationNum"
            label="协议子账号日咨询量"
            width="150"
            :formatter="tableColumn"
          >
          </el-table-column>
          <el-table-column prop="serviceType" label="客服类型" :formatter="tableColumn">
            <template slot-scope="scope">
              <span>{{
                scope.row.serviceType == 1 ? "普通客服" : (scope.row.serviceType == 2 ? "金牌客服" : "临时客服")
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="服务时间范围" width="120" :formatter="tableColumn">
            <template slot-scope="scope">
              <span>{{ scope.row.startTime }}</span
              ><br />
              <span>{{ scope.row.endTime }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="serviceDay" label="店铺服务天数" width="120" :formatter="tableColumn">
            <template slot-scope="scope">
              <span class="service-day">{{ scope.row.serviceDay || 0 }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="dataType" label="约定数据标准" width="120" :formatter="tableColumn">
            <template slot-scope="scope">
              <span>{{
                scope.row.dataType == 1
                  ? "基础"
                  : scope.row.dataType == 2
                  ? "专业"
                  : "旗舰"
              }}</span>
            </template>
          </el-table-column>

          <el-table-column prop="serviceGroupNum" label="服务对接群数量" width="120" :formatter="tableColumn">
          </el-table-column>
          <el-table-column prop="nickName" label="创建人" width="120" :formatter="tableColumn">
          </el-table-column>
          <el-table-column prop="createTime" label="创建时间" width="160" :formatter="tableColumn">
          </el-table-column>
          <!-- <el-table-column label="操作" fixed="right">
            <template slot-scope="scope">
              <el-popconfirm
                title="确定删除吗？"
                @confirm="
                  () => {
                    delItem(scope.row);
                  }
                "
              >
                <el-button type="text" slot="reference">删除</el-button>
              </el-popconfirm>
            </template>
          </el-table-column> -->
        </el-table>
        <el-pagination
          @size-change="(e) => pageFun(e, 'pageSize')"
          @current-change="(e) => pageFun(e)"
          style="text-align: center"
          :page-sizes="pagination.pageSizes"
          :page-size="params.pageSize"
          :current-page="params.page"
          layout="total, prev, pager, next,sizes, jumper"
          :total="pagination.total"
        >
        </el-pagination>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
//import引入的组件需要注入到components中才能使用
import CommonDate from "../../components/common/commonDate";
import { Cascader } from "ant-design-vue";
import { getPlatList } from "../../service/common.js";
import { priceList } from "../../service/quoted.js";
import { tableColumn } from "../../utils/index.js";

export default {
  name: "Quotes",
  components: {
    CommonDate,
    aCascader: Cascader
  },
  props: {},
  data() {
    return {
      tableColumn,
      activeName: "first",
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100]
      },
      params: {
        pageNum: 1,
        pageSize: 10
      },
      options: [
        {
          value: "all",
          label: "全部"
        },
        {
          value: "pending",
          label: "待销售催款"
        },
        {
          value: "confirming",
          label: "待财务确认"
        },
        {
          value: "complete",
          label: "交易完成"
        }
      ],
      //客服类型下拉
      serviceList: [
        {
          value: 1,
          label: "普通客服"
        },
        {
          value: 2,
          label: "金牌客服"
        },
        {
          value: 3,
          label: "临时客服"
        }
      ],
      //约定数据类型下拉
      dataTypeList: [
        {
          value: 1,
          label: "基础版"
        },
        {
          value: 2,
          label: "专业版"
        },
        {
          value: 3,
          label: "旗舰版"
        }
      ],
      platformId: [],
      platList: [], // 所属平台类目下拉
      tableData: [],
      loading: false,
      spanData: [] // 存放计算好的合并单元格的规则
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    commonGetDate(startTime, endTime) {
      this.params.startTime = startTime;
      this.params.endTime = endTime;
      this.getQuotesList();
    },
    commonFun() {
      this.params.pageSize = 10;
      this.params.page = 1;
      this.getQuotesList();
    },
    pageFun(e, type) {
      // 分页
      if (type == "pageSize") {
        this.params.pageSize = e;
      } else {
        this.params.pageNum = e;
      }
      this.getQuotesList();
    },
    resetBtn() {
      (this.params = {
        page: 1,
        pageSize: 10
      }),
        this.$refs.commonReset.resetFun();
      this.platformId = [];
      this.getQuotesList();
    },
    handleClick(tab) {
      if (tab.name == "second") {
        this.getQuotesList();
      }
    },
    async getPlatList() {
      // 所属平台/类目下拉
      let resData = (await getPlatList({ pageNum: -1 })).data;
      this.platList = resData;
    },
    async getQuotesList() {
      this.loading = true;
      let platformId = [...this.platformId];
      let params = { ...this.params };
      if (platformId && platformId.length) {
        params.platformId = platformId[0];
        if (platformId[1]) {
          params.categoryId = platformId[1];
        }
      }
      const { data } = await priceList(params);
      this.tableData = data.records;
      this.getSpanData(this.tableData); //合并单元格
      this.pagination.total = data.total;
      this.pagination.pageSize = data.size;
      this.loading = false;
    },
    delItem(row) {
      console.log(row, "行");
      this.getQuotesList();
    },
    // 计算需要合并的单元格
    getSpanData(data) {
      this.spanData = [];
      for (let i = 0; i < data.length; i++) {
        if (i == 0) {
          this.spanData.push(1);
          this.pos = 0;
        } else {
          if (data[i].qSn == data[i - 1].qSn) {
            this.spanData[this.pos] += 1;
            this.spanData.push(0);
          } else {
            this.spanData.push(1);
            this.pos = i;
          }
        }
      }
    },
    // 合并单元格
    objectSpanMethod({ rowIndex, columnIndex }) {
      if (
        columnIndex === 0 ||
        columnIndex === 1 ||
        columnIndex === 14 ||
        columnIndex === 15 ||
        columnIndex === 16
      ) {
        const _row = this.spanData[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col
        };
      }
    },
    seeMore(row) {
      this.$router.push({ path: "/quoted/complete", query: { id: row.oId } });
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getPlatList();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {}
};
</script>

<style lang="less" scoped>
.quotes-container {
  width: 100%;
  height: 100%;

  /deep/.el-tabs {
    height: 100%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
  }
  /deep/.el-tabs__content {
    overflow: auto;
    // height: 9% !important;
    flex: 1;
  }
  /deep/ #pane-first {
    height: 100%;
    background: url("~@/assets/images/baojia_bg.png") -20% 50% no-repeat;
    background-size: 80%;
  }
  .welcome {
    // position: absolute;
    // top: 194px;
    // right: 230px;
    float: right;
    margin: 194px 230px;
    text-align: left;
    font-size: 36px;
    color: #101010;
    /deep/.el-button {
      width: 168px;
      height: 56px;
      margin-top: 129px;
      margin-bottom: 230px;
      span {
        font-size: 24px !important;
      }
    }
  }
  .footer {
    /deep/ .el-button--text {
      position: absolute;
      left: 40px;
      bottom: 30px;
      color: #999 !important;
      font-size: 18px;
      text-decoration: underline;
    }
    /deep/ .el-button--text:hover {
      position: absolute;
      left: 40px;
      bottom: 30px;
      color: #409eff !important;
      font-size: 18px;
      text-decoration: underline;
    }
  }
  /deep/.el-tabs__content {
    padding: 16px;
  }
  /deep/.el-table .cell {
    white-space: nowrap;
  }
  .service-day {
    display: inline-block;
    // width: 20px;
    padding: 3px 10px;
    background: #f2f9ff;
    color: #1890ff;
    border: 1px solid #1890ff;
    border-radius: 2px;
    font-size: 12px;
  }
  .sum-price {
    color: #ec9429;
  }
}
</style>
